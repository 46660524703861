

.single-operation__main-container{
    border: 1px solid #1b808a;
    height: 60px;
    border-radius: 10px;
    margin: 8px auto;
    color: #1b808a;
    position: relative;
    cursor: pointer;
    max-width: 768px;
    background-color: #fff;
    transition: height 300ms;
}

.single-operation__main-container-big{
    max-width: 768px;
    border: 1px solid #1b808a;
    height: 200px;
    border-radius: 10px;
    margin: 8px auto;
    color: #1b808a;
    position: relative;
    cursor: pointer;
    background-color: #fff;
    transition: height 300ms;
}

.single-operation__container{
    height: 60px;
    border-radius: 10px;
    margin: 8px auto;
    color: #1b808a;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 1fr;
    align-items: center;
    padding-right: 10px;
}
.single-operation__subtitle {
    position: absolute;
    top: 5px;
    right: 40px;
    font-size: 10px;
    color: #ccc;
    font-weight: 600;
}
.single-operation__date {
    font-size: 10px;
    color: #ccc;
    font-weight: 600;
}
.single-operation__container svg{
    font-size: 24px;
}
.single-operation__details{
    /* border: 1px solid #000; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    padding: 0 10px;
}

.single-operation__hide{
    display: none;
}
