


.add-button__container {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 100px;
    /* border: 1px solid #ccc; */
    border-radius: 15px;
    background-color: #2a9ca6;
    color: #fff;
}

.add-button__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin: 0;
    height: 100%;
    width: 100%
}

.add-button__body svg {
    font-size: 25px;
}