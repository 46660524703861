
.skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.box-card__container {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 100px;
    /* border: 1px solid #ccc; */
    border-radius: 15px;
    background-color: #2a9ca6;
    color: #fff;
}

.box-card__header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    /* border-bottom: 1px solid #ccc; */
    padding: 0 10px;
}

.box-card__header span{
    border: 1px solid #ccc;
    padding: 0 5px;
    font-size: 12px;
    border-radius: 5px;
    letter-spacing: 0.5px;
}
.box-card__header svg{
    padding: 2px 8px;
    font-size: 24px;
}

.box-card__title {
    font-size: 12px;
    margin: 5px 10px 0px 0px;
}

.box-card__body {
    font-size: 16px;
    margin: 0px 10px 0px 0px;
    font-weight: 600;
}