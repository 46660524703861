


.balance-card__container {
    display: flex;
    flex-direction: column;
    max-width: 768px;
    height: 110px;
    /* border: 1px solid #ccc; */
    border-radius: 15px;
    background-color: #1b808a;
    margin: 0 auto;
    /* background-color: #2a9ca6; */
    /* background-color: rgb(52, 52, 52); */
    color: #fff;
    margin-bottom: 10px;
}

.balance-card__header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    /* border-bottom: 1px solid #ccc; */
    padding: 0 10px;
}

.balance-card__header p{
    font-size: 12px;
    color: #fcfcfc;
}
.balance-card__header span{
    margin-top: 10px;
    border: 1px solid #fff;
    padding: 0 5px;
    font-size: 12px;
    border-radius: 5px;
    letter-spacing: 0.5px;
}


.balance-card__title{
    font-size: 24px;
    margin: 0px 10px 0px 10px;
    font-weight: 600;
    border-bottom: 1px solid #fff;
}

.balance-card__body {
    text-align: center;
    font-size: 12px;
    margin: 7px 0;
    font-weight: 500;
}