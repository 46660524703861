


.dash-card__container {
    border: 2px solid var(--LINK-COLOR);
    height: 130px;
    width: 160px;
    border-radius: 15px;
  }
  
  .dash-card__title {
    text-align: center;
    height: 50px;
    width: 100%;
    padding: 5px 10px;
    font-size: 30px;
    color: var(--LINK-COLOR) ;
  }
  .dash-card__body{
    padding: 5px 10px;
    height: 90px;
  }
  .dash-card__container p {
    font-size: 26px;
    color: var(--LINK-COLOR);
    font-weight: 700;
    text-align: center;
  }