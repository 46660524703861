@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* || VARIABLES */

:root {
  /* COLORS */
  --BGCOLOR: #fcfcfd;
  --BORDER-COLOR: #333;
  --COLOR: #000;
  --ERROR: firebrick;
  --INACTIVE: #2a9ca6;
  --SELECTED: #2a9ca6;
  --BORDERS: #2a9ca6;
  --LINK-COLOR: #2a9ca6;
  --LINK-HOVER: #36c6d3;
  --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-OPEN: limegreen;
  --STATUS-CLOSED: firebrick;
  --TABLE-BGCOLOR: #fff;
  --TABLE-COLOR: #000;
  --INPUT-INVALID: #f00;
  --BUTTON-COLOR: #f8d969;
  --BUTTON-HOVER-COLOR: #f5c827;
  --BACK-BUTTON-COLOR: #82d0f2;
  --BACK-BUTTON-HOVER-COLOR: #50a7cc;
}

/* || UTILITY CLASSES */
.errmsg {
  color: var(--ERROR);
  padding: 0.25em;
  margin-bottom: 0.5em;
}

.errmsg a:any-link {
  color: var(--ERROR);
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}

.icon-button {
  width: 48px;
  height: 48px;
  font-size: 2.5rem;
  background-color: transparent;
  border: none;
  color: var(--COLOR);
  display: grid;
  place-content: center;
}

.icon-button:hover,
.icon-button:focus-visible {
  transform: scale(1.2);
}

.icon-button[disabled] {
  visibility: hidden;
}

/* || GENERAL STYLES */

body {
  font-family: "Baloo Bhaijaan 2", cursive;
  min-height: 100vh;
  background-color: var(--BGCOLOR);
  color: var(--COLOR);
  background-size: cover;
  background-position: top right;
}

input,
button,
textarea,
select {
  font: inherit;
}

#root {
  /* React root div */
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

a {
  text-decoration: none;
}

a:any-link {
  color: var(--LINK-COLOR);
}

a:hover,
a:focus-visible {
  color: var(--LINK-HOVER);
}

/* || PAGES */

.public,
.welcome {
  /* display: flex; */
  flex-direction: column;
  gap: 1rem;
}

.welcome__dash-cards-container {
    display: grid;
    grid-template-columns: auto auto; 
    gap: 10px; 
    justify-items: center;
    max-width: 768px;
    justify-content: center;
    margin: 0 auto;
}

.welcome-mobile {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: -43px;
  z-index: 2;
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
}

.public {
  padding: 1em;
  flex-grow: 1;
  text-align: center;
}

.public__main,
.login {
  flex-grow: 1;
  border-top: 2px solid var(--BORDER-COLOR);
  border-bottom: 2px solid var(--BORDER-COLOR);
  padding: 1em 0;
}

.public__addr {
  margin-top: 1em;
}

/* .welcome a::after {
  content: "← ";
} */


.add-budget__members__container {
    overflow: scroll;
    height: 120px;
    border-radius: 10px;
    overflow-x: hidden;
    border: 2px solid var(--BORDERS);
    margin-top: 10px;
  }
  
  .add-budget__member {
    height: 40px;
    width: 90%;
    border: 1px solid var(--BORDERS);
    margin: 5px auto;
    border-radius: 8px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1e6d74;
  }

/* || DASH HEADER, CONTAINER & FOOTER */

.dash-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  padding: 0 0.5em;
  border-bottom: 1px solid var(--BORDER-COLOR);
}

.dash-header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.dash-header__container--small {
  max-width: 800px;
}

.dash-header__nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 0.5em;
}

.dash-container {
  padding: 0.75em 0.5em 0.5em;
  flex-grow: 1;
  position: relative;
}

.dash-footer {
  font-size: 1rem;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: var(--BGCOLOR);
  padding: 0.5em;
  border-top: 1px solid var(--BORDER-COLOR);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 1em;
}

.dash-footer__button {
  font-size: 1rem;
  width: auto;
  height: auto;
}

/* || TABLES - GENERAL */

thead,
tbody,
tr {
  display: contents;
}

.table {
  font-size: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  color: var(--TABLE-COLOR);
  gap: 0.1em;
}

.table__button {
  padding: 0.25em;
  font-size: 1.5rem;
  color: var(--TABLE-COLOR);
}

.table__thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table__th,
.table__cell {
  background-color: var(--TABLE-BGCOLOR);
  text-align: right;
  border: 1px solid var(--TABLE-COLOR);
  padding: 0.5em;
}

/* .table__cell:last-child { */
.table__cell {
  display: grid;
  place-content: center;
}

.table__cell--inactive {
  background-color: var(--INACTIVE);
}

/* ||  NOTE */

.note__created,
.note__updated,
.note__username {
  display: none;
}

.note__status {
  font-weight: bold;
}

.note__status--open {
  color: var(--STATUS-OPEN);
}

.note__status--closed {
  color: var(--STATUS-CLOSED);
}

/* || FORM - GENERAL */

.form {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75em;
  max-width: 800px;
  margin: 0 5%;
}

.form__checkbox-container {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.5em;
}

.form__persist {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5em;
}

.form__title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__input,
.form__submit-button {
  padding: 0.5em;
  border-radius: 15px;
}

.form__input--text {
  min-height: 150px;
}

.form__input--incomplete {
  border: 1px solid var(--INPUT-INVALID);
  outline: 1px solid var(--INPUT-INVALID);
}

.form__checkbox {
  width: 24px;
  height: 24px;
}

.form__select {
  /* width: fit-content; */
  padding: 0.25em;
  border-radius: 15px;
}

.form__action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
  position: absolute;
  left: 0.5em;
}

.form__row {
  display: flex;
  flex-flow: row nowrap;
  gap: 2em;
}

.form__divider {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
}

/* model styles */
.dash__model__container {
  display: grid;
  justify-content: center;
}

.dash__model__image__container {
  max-width: 300px;
}

.dash__model__image__container img {
  width: 100%;
}
.dash__model__title__container {
  font-size: 24px;
  font-weight: 700;
}
.dash__model__card__container {
  text-align: center;
}

.dash__model__btn {
  background-color: var(--BUTTON-COLOR);
  box-shadow: 8px 12px 2px 1px rgba(0, 0, 255, 0.2);
  width: 280px;
  padding: 8px 16px;
  border-radius: 18px;
  margin-bottom: 20px;
}
.dash__model__btn:hover {
  background-color: var(--BUTTON-HOVER-COLOR);
  cursor: pointer;
}

/* DASH CARD STYLE */

.card__container {
  border: 2px solid var(--LINK-COLOR);
  height: 130px;
  min-width: 200px;
  border-radius: 15px;
}

.card__title__container {
  border-radius: 10px 10px 10px 10px;
  height: 50px;
  border-bottom: 2px solid var(--LINK-COLOR);
  width: 100%;
  padding: 5px 10px;
  background-color: rgba(204, 204, 204, 0.467);
}
.card__body__container {
  padding: 5px 10px;
  height: 90px;
}
.card__container p {
  font-size: 26px;
  color: var(--LINK-COLOR);
  font-weight: 700;
  text-align: center;
}



/* REPORTS STYLES */

.reports__index-container{
    max-width: 768px;
    margin: 0 auto;
}

.table-budget-report {
    grid-template-columns: repeat(3, 1fr);
}

.table-first-report {
    grid-template-columns: repeat(4, 1fr);
}

.table__cell-report {
    font-size: 14px;
    position: relative;
}
.table__cell-report-member{
    position: absolute;
    font-size: 12px;
    opacity: .3;
    left: 10px;
    bottom: 0
}

.table__th-report {
    font-size: 16px;
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: #1e6d74;
    color: #fff;
}

.report-filter-select{
    min-width: 200px;
}

/* DASH CARDS STYLE */
.dash__cards__container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-items: center;
}

.dash__main__btn {
  background-color: var(--BUTTON-COLOR);
  width: 280px;
  padding: 8px 16px;
  border-radius: 18px;
  margin-bottom: 20px;
}
.dash__main__btn:hover {
  background-color: var(--BUTTON-HOVER-COLOR);
  cursor: pointer;
}

.dash__back__btn {
  background-color: var(--BACK-BUTTON-COLOR);
  width: 280px;
  padding: 8px 16px;
  border-radius: 18px;
  margin-bottom: 20px;
}
.dash__back__btn svg {
  margin-left: 20px;
}
.dash__back__btn:hover {
  background-color: var(--BACK-BUTTON-HOVER-COLOR);
  cursor: pointer;
}





@media screen and (max-width: 400px) {
  h1 {
    font-size: 2rem;
  }

  .dash-header__nav {
    gap: 0;
  }

  .icon-button {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 1.25rem;
  }

  .dash-header {
    padding: 0 1em;
  }

  .dash-container {
    padding: 1em;
    /* margin: 0 auto; */
  }

  .table {
    font-size: 1.5rem;
  }

  .table--notes {
    grid-template-columns: repeat(1, 1fr) 2fr repeat(2, 1fr);
  }

  .note__created,
  .note__updated,
  .note__username {
    display: block;
  }

  .form__action-buttons {
    position: static;
  }
}


