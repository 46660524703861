.mobile-footer {
  font-size: 1.5rem;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
  padding: 0.5em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  gap: 1em;
  padding-top: 24px;
  box-shadow: rgb(24 39 75 / 6%) 0px 1px 20px -6px;
  /* box-shadow: rgba(0, 0, 0, 0.45) 15px 5px 10px 6px; */
}

.mobile-footer span {
  font-size: 0.8rem;
}

.mobile-footer__button {
  font-size: 1rem;
  width: auto;
  height: auto;
}

.mobile-icon-button {
  width: 48px;
  height: 48px;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: var(--COLOR);
  display: grid;
  place-content: center;
  justify-items: center;
}

.mobile__button__selected{
    color: var(--SELECTED);
}


.mobile-icon-button[disabled] {
  visibility: hidden;
}
