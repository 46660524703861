
.action-buttons__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
    max-width: 768px;
    gap: 10px;
    justify-items: stretch;
    margin: 0 auto;
}

.action-button__container {
  border: 2px solid var(--LINK-COLOR);
  height: 100px;
  min-width: 100px;
  border-radius: 15px;
  margin-top: 20px;
  color: var(--LINK-COLOR);
}
.action-button__container:hover {
  color: white;
  background-color: var(--LINK-COLOR);
}

.action-button__title {
  text-align: center;
  height: 50px;
  width: 100%;
  padding: 5px 10px;
  font-size: 36px;
}
.action-button__body {
  padding: 5px 10px;
  height: 90px;
}
.action-button__container p {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 450px) {
    .action-button__container p {
        font-size: 18px;
    }
}
